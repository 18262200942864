<template>
  <div class="content-editor">
    <!-- {{innerContent}} -->
    <component
      :is="dynComponent"
      v-model="innerContent"
      :config="editorConfig"
      :editor-url="ckeditorUrl"
      style="width:100%;height:100%"
      @ready="onEditorReady"
      @focus="onEditorFocus"
      @input="checkContentHeight"
    />
  </div>
</template>

<script>
export default {
  components: {
  },
  activated () {
    this.dynComponent = 'ckeditor'
  },
  deactivated () {
    this.dynComponent = null
    this.editorInstance = null
  },
  mounted () {
    this.dynComponent = 'ckeditor'
  },
  computed: {
    innerContent: {
      get () {
        return this.content
      },
      set (v) {
        if (this.editorInstance) {
          this.$emit('update:content', v)
        }
      }
    },
    _tokens: {
      get () {
        return this.tokens
      }
    },
    editorConfig () {
      const c = {
        readOnly: this.readonly,
        width: '100%',
        height: '100%',
        allowedContent: true,
        extraPlugins: 'imagebase, mentions, sharedspace, editorplaceholder',
        contentsCss: ['./ckeditor/contents.css'],
        editorplaceholder: this.placeholder,
        toolbar: [
          [
            'Font',
            'FontSize',
            'Bold',
            'Italic',
            'Underline',
            'JustifyLeft',
            'JustifyCenter',
            'JustifyRight',
            'JustifyBlock',
            'Outdent',
            'Indent',
            'TextColor',
            'BGColor',
            'BulletedList',
            'NumberedList',
            'Link',
            'Image',
            'Table',
            'PageBreak'
          ]
        ]
      }

      if (this.sharedToolbarId) {
        c.sharedSpaces = { top: this.sharedToolbarId }
      }

      return c
    }
  },
  data () {
    return {
      contentHeight: 0,
      dynComponent: null,
      editorInstance: null,
      ckeditorUrl: './ckeditor/ckeditor.js'
    }
  },
  methods: {
    checkContentHeight () {
      const el = this.$el?.querySelector('iframe')?.contentDocument
      if (!el) { return }
      const styles = document.defaultView.getComputedStyle(el.body)
      const newHeight = el.body.scrollHeight + Number(styles.marginTop.replace('px', '')) + Number(styles.marginBottom.replace('px', ''))
      if (this.contentHeight !== newHeight) {
        this.contentHeight = newHeight
        this.$emit('content-height', this.contentHeight)
      }
    },
    onEditorFocus () {
      this.$emit('content-editor-focused', this.editorInstance)
    },
    onEditorReady (instance) {
      this.editorInstance = instance

      this.setReadOnlyUI(this.readonly)
      this.$emit('content-editor-ready', this.editorInstance)
      this.checkContentHeight()
    },
    setReadOnlyUI (readonly) {
      const e = this.editorInstance.container.getDocument().getById(this.editorInstance.id + '_top')
      if (e) {
        this.editorInstance.setReadOnly(readonly)
        e.setStyle('display', readonly ? 'none' : 'block')
      }
    }
  },
  watch: {
    readonly: {
      // immediate: true,
      async handler (n, o) {
        await this.$waitFor(() => this.editorInstance)
        if (!this.editorInstance || !this.dynComponent) return
        this.setReadOnlyUI(n)
      }
    }
  },
  props: {
    sharedToolbarId: String,
    placeholder: String,
    content: String,
    readonly: Boolean
  }
}
</script>

<style lang="stylus">
.cke_reset.cke_chrome, .cke_chrome>.cke_inner
  height 100%
</style>
